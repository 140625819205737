<template>
  <div id="app">
    <router-view></router-view>
    <v-loading v-show="isLoading"></v-loading>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vLoading from "./components/common/Loading.vue";
export default {
  data() {
    return {
    }
  },
  computed: {
  ...mapState(["isLoading"])
  },
  components: {
    vLoading
  }
}
</script>

<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.css"; /*深色主题*/
.loading {
 width: 100%;
 height: 100%;
 left: 0;
 top: 0;
 position: fixed;
 padding-top: 5.333333rem;
}
.loader {
  margin: 0 auto;
  position: relative; /*脱离文档流*/
  top: 25%; /*偏移*/
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>