import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/dataView',
      component: () => import(/* webpackChunkName: "dataView" */ '../components/page/DataView.vue'),
      meta: { title: '数据大屏' }
    },
    {
      path: '/dataAdView',
      component: () => import(/* webpackChunkName: "dataView" */ '../components/page/dataScreen/index.vue'),
      meta: { title: '数据大屏' }
    },
    {
      path: '/home',
      component: () => import(/* webpackChunkName: "home" */ '../components/common/Home.vue'),
      meta: { title: '主页' },
      redirect: '/dashboard',
      children: [
        {
          path: '/dashboard',
          component: () => import(/* webpackChunkName: "dashboard" */ '../components/page/Dashboard.vue'),
          meta: { title: '系统首页' }
        },
        {
          path: '/org',
          component: () => import(/* webpackChunkName: "org" */ '../components/page/org/Org.vue'),
          meta: { title: '组织管理' }
        },
        {
          path: '/project',
          component: () => import(/* webpackChunkName: "project" */ '../components/page/project/Project.vue'),
          meta: { title: '项目管理' }
        },
        {
          path: '/projectDetail',
          component: () => import(/* webpackChunkName: "projectDetail" */ '../components/page/project/ShowProject.vue'),
          meta: { title: '项目详情' }
        },
        {
          path: '/role',
          component: () => import(/* webpackChunkName: "role" */ '../components/page/role/Role.vue'),
          meta: { title: '角色管理' }
        },
        {
          path: '/resource',
          component: () => import(/* webpackChunkName: "resource" */ '../components/page/resource/Resource.vue'),
          meta: { title: '界面资源管理' }
        },
        {
          path: '/user',
          component: () => import(/* webpackChunkName: "user" */ '../components/page/user/User.vue'),
          meta: { title: '用户管理' }
        },
        {
          path: '/attachment',
          component: () => import(/* webpackChunkName: "attachment" */ '../components/page/attachment/Attachment.vue'),
          meta: { title: '附件管理' }
        },
        {
          path: '/project/diagram',
          component: () => import(/* webpackChunkName: "projectDiagram" */ '../components/page/project/ProjectDiagram.vue'),
          meta: { title: '图片-示意图' }
        },
        {
          path: '/project/map/diagram',
          component: () => import(/* webpackChunkName: "projectMapDiagram" */ '../components/page/project/ProjectMapDiagram.vue'),
          meta: { title: '地图-示意图' }
        },
        {
          path: '/project/module',
          component: () => import(/* webpackChunkName: "projectModule" */ '../components/page/project/ProjectModule.vue'),
          meta: { title: '项目模块' }
        },
        {
          path: '/weixin/user',
          component: () => import(/* webpackChunkName: "wxuser" */ '../components/page/weixin/Wxuser.vue'),
          meta: { title: '微信用户管理' }
        },
        {
          path: '/weixin/push/config',
          component: () => import(/* webpackChunkName: "wxPushCfg" */ '../components/page/weixin/WxPushCfg.vue'),
          meta: { title: '微信推送配置' }
        },


        ///////////////预警配置////////////////
        {
          path: '/alarm/config',
          component: () => import(/* webpackChunkName: "alarmConfig" */ '../components/page/alarm/AlarmConfig.vue'),
          meta: { title: '预警配置' }
        },
        {
          path: '/alarm/list',
          component: () => import(/* webpackChunkName: "alarmList" */ '../components/page/alarm/AlarmList.vue'),
          meta: { title: '预警列表' }
        },


        // 消息管理
        {
          path: '/message/internal',
          component: () => import(/* webpackChunkName: "internal" */ '../components/page/message/Internal.vue'),
          meta: { title: '站内消息' }
        },
        {
          path: '/message/sms',
          component: () => import(/* webpackChunkName: "sms" */ '../components/page/message/Sms.vue'),
          meta: { title: '短信消息' }
        },
        {
          path: '/message/config',
          component: () => import(/* webpackChunkName: "msgConfig" */ '../components/page/message/MsgConfig.vue'),
          meta: { title: '消息配置' }
        },
        {
          path: '/message/summary',
          component: () => import(/* webpackChunkName: "msgSummary" */ '../components/page/message/MsgSummary.vue'),
          meta: { title: '消息统计' }
        },


        ///////////////数据分析////////////////
        {
          path: '/statis/weiyi',
          component: () => import(/* webpackChunkName: "statisWeiyi" */ '../components/page/datacenter/analysis/StatisWeiyi.vue'),
          meta: { title: '表面位移分析' }
        },
        {
          path: '/statis/shenya',
          component: () => import(/* webpackChunkName: "statisShenya" */ '../components/page/datacenter/analysis/StatisShenya.vue'),
          meta: { title: '浸润线分析' }
        },
        {
          path: '/statis/yuliang',
          component: () => import(/* webpackChunkName: "statisYuliang" */ '../components/page/datacenter/analysis/StatisYuliang.vue'),
          meta: { title: '雨量分析' }
        },


        ///////////////数据比对////////////////
        {
          path: '/data/cp/gantan',
          component: () => import(/* webpackChunkName: "gantanBd" */ '../components/page/bidui/GantanBd.vue'),
          meta: { title: '干滩比对' }
        },
        {
          path: '/data/cp/shenya',
          component: () => import(/* webpackChunkName: "shenyaBd" */ '../components/page/bidui/ShenyaBd.vue'),
          meta: { title: '浸润线比对' }
        },
        {
          path: '/data/cp/weiyi',
          component: () => import(/* webpackChunkName: "weiyiBd" */ '../components/page/bidui/WeiyiBd.vue'),
          meta: { title: '表面位移比对' }
        },


        ///////////////实时监测////////////////
        {
          path: '/data/joblogs',
          component: () => import(/* webpackChunkName: "jobLogs" */ '../components/page/datacenter/realtime/JobLogs.vue'),
          meta: { title: '作业日志' }
        },
        {
          path: '/data/cmdlogs',
          component: () => import(/* webpackChunkName: "cmdLogs" */ '../components/page/datacenter/realtime/CmdLogs.vue'),
          meta: { title: '指令日志' }
        },
        {
          path: '/data/realtime/shenya',
          component: () => import(/* webpackChunkName: "shenyaRt" */ '../components/page/datacenter/realtime/ShenyaRt.vue'),
          meta: { title: '浸润线监测' }
        },
        {
          path: '/data/realtime/cexie',
          component: () => import(/* webpackChunkName: "cexieRt" */ '../components/page/datacenter/realtime/CexieRt.vue'),
          meta: { title: '内部位移监测' }
        },
        {
          path: '/data/realtime/yuliang',
          component: () => import(/* webpackChunkName: "yuliangRt" */ '../components/page/datacenter/realtime/YuliangRt.vue'),
          meta: { title: '雨量监测' }
        },
        {
          path: '/data/realtime/wuwei',
          component: () => import(/* webpackChunkName: "wuweiRt" */ '../components/page/datacenter/realtime/WuweiRt.vue'),
          meta: { title: '水位监测' }
        },
        {
          path: '/data/realtime/zhuodu',
          component: () => import(/* webpackChunkName: "zhuoduRt" */ '../components/page/datacenter/realtime/ZhuoduRt.vue'),
          meta: { title: '浊度仪监测' }
        },
        {
          path: '/data/realtime/gantan',
          component: () => import(/* webpackChunkName: "gantanRt" */ '../components/page/datacenter/realtime/GantanRt.vue'),
          meta: { title: '干滩监测' }
        },
        {
          path: '/data/realtime/chaogao',
          component: () => import(/* webpackChunkName: "chaogaoRt" */ '../components/page/datacenter/realtime/ChaogaoRt.vue'),
          meta: { title: '超高监测' }
        },
        {
          path: '/data/realtime/gnss',
          component: () => import(/* webpackChunkName: "gnssRt" */ '../components/page/datacenter/realtime/GnssRt.vue'),
          meta: { title: '表面位移监测' }
        },
        {
          path: '/data/realtime/mingqu',
          component: () => import(/* webpackChunkName: "mingquRt" */ '../components/page/datacenter/realtime/MingquRt.vue'),
          meta: { title: '明渠流量监测' }
        },
        {
          path: '/data/realtime/quanzhan',
          component: () => import(/* webpackChunkName: "quanzhanRt" */ '../components/page/datacenter/realtime/QuanzhanRt.vue'),
          meta: { title: '表面位移(全站仪)' }
        },


        ///////////////历史数据////////////////
        {
          path: '/data/history/quanzhan',
          component: () => import(/* webpackChunkName: "quanzhanHt" */ '../components/page/datacenter/history/QuanzhanHt.vue'),
          meta: { title: '表面位移(全站仪)' }
        },
        {
          path: '/data/history/shenya',
          component: () => import(/* webpackChunkName: "shenyaHt" */ '../components/page/datacenter/history/ShenyaHt.vue'),
          meta: { title: '浸润线数据' }
        },
        {
          path: '/data/history/cexie',
          component: () => import(/* webpackChunkName: "cexieHt" */ '../components/page/datacenter/history/CexieHt.vue'),
          meta: { title: '内部位移数据' }
        },
        {
          path: '/data/history/yuliang',
          component: () => import(/* webpackChunkName: "yuliangHt" */ '../components/page/datacenter/history/YuliangHt.vue'),
          meta: { title: '雨量数据' }
        },
        {
          path: '/data/history/wuwei',
          component: () => import(/* webpackChunkName: "wuweiHt" */ '../components/page/datacenter/history/WuweiHt.vue'),
          meta: { title: '水位数据' }
        },
        {
          path: '/data/history/zhuodu',
          component: () => import(/* webpackChunkName: "zhuoduHt" */ '../components/page/datacenter/history/ZhuoduHt.vue'),
          meta: { title: '浊度数据' }
        },
        {
          path: '/data/history/chaogao',
          component: () => import(/* webpackChunkName: "chaogaoHt" */ '../components/page/datacenter/history/ChaogaoHt.vue'),
          meta: { title: '超高数据' }
        },
        {
          path: '/data/history/gantan',
          component: () => import(/* webpackChunkName: "gantanHt" */ '../components/page/datacenter/history/GantanHt.vue'),
          meta: { title: '干滩数据' }
        },
        {
          path: '/data/history/gnss',
          component: () => import(/* webpackChunkName: "gnssHt" */ '../components/page/datacenter/history/GnssHt.vue'),
          meta: { title: '表面位移数据' }
        },
        {
          path: '/data/history/mingqu',
          component: () => import(/* webpackChunkName: "mingquHt" */ '../components/page/datacenter/history/MingquHt.vue'),
          meta: { title: '明渠流量数据' }
        },


        ///////////////设备管理////////////////
        {
          path: '/device/com',
          component: () => import(/* webpackChunkName: "virtualCom" */ '../components/page/device/VirtualCom.vue'),
          meta: { title: '虚拟串口' }
        },
        {
          path: '/device/duanmian',
          component: () => import(/* webpackChunkName: "duanmian" */ '../components/page/device/Duanmian.vue'),
          meta: { title: '断面管理' }
        },
        {
          path: '/device/konghao',
          component: () => import(/* webpackChunkName: "konghao" */ '../components/page/device/Konghao.vue'),
          meta: { title: '孔号管理' }
        },
        {
          path: '/device/mcu',
          component: () => import(/* webpackChunkName: "mcu" */ '../components/page/device/Mcu.vue'),
          meta: { title: 'MCU' }
        },
        {
          path: '/device/zhuodu',
          component: () => import(/* webpackChunkName: "zhuodu" */ '../components/page/device/Zhuodu.vue'),
          meta: { title: '浊度仪' }
        },
        {
          path: '/device/yuliang',
          component: () => import(/* webpackChunkName: "yuliang" */ '../components/page/device/Yuliang.vue'),
          meta: { title: '雨量计' }
        },
        {
          path: '/device/wuwei',
          component: () => import(/* webpackChunkName: "wuwei" */ '../components/page/device/Wuwei.vue'),
          meta: { title: '水位计' }
        },
        {
          path: '/device/shenya',
          component: () => import(/* webpackChunkName: "shenya" */ '../components/page/device/Shenya.vue'),
          meta: { title: '渗压计' }
        },
        {
          path: '/device/cexie',
          component: () => import(/* webpackChunkName: "cexie" */ '../components/page/device/Cexie.vue'),
          meta: { title: '测斜仪' }
        },
        {
          path: '/device/gantan',
          component: () => import(/* webpackChunkName: "gantan" */ '../components/page/device/Gantan.vue'),
          meta: { title: '干滩' }
        },
        {
          path: '/device/chaogao',
          component: () => import(/* webpackChunkName: "chaogao" */ '../components/page/device/Chaogao.vue'),
          meta: { title: '超高' }
        },
        {
          path: '/device/quanzhan',
          component: () => import(/* webpackChunkName: "qzmain" */ '../components/page/device/quanzhan/Qzmain.vue'),
          meta: { title: '全站仪' }
        },
        {
          path: '/device/gnss',
          component: () => import(/* webpackChunkName: "gpsMain" */ '../components/page/device/gnss/GpsMain.vue'),
          meta: { title: 'GNSS' }
        },
        {
          path: '/device/barLevel',
          component: () => import(/* webpackChunkName: "barLevel" */ '../components/page/device/BarLevel.vue'),
          meta: { title: '坝体级别管理' }
        },
        {
          path: '/device/mingqu',
          component: () => import(/* webpackChunkName: "mingqu" */ '../components/page/device/Mingqu.vue'),
          meta: { title: '明渠流量计' }
        },
        {
          path: '/device/camera',
          component: () => import(/* webpackChunkName: "camera" */ '../components/page/device/Camera.vue'),
          meta: { title: '摄像头' }
        },


        // 边端应用
        {
          path: '/frontapp/list',
          component: () => import(/* webpackChunkName: "frontapp" */ '../components/page/frontapp/Front.vue'),
          meta: { title: '边端应用' }
        },
        {
          path: '/frontapp/alarm',
          component: () => import(/* webpackChunkName: "frontAlarmConfig" */ '../components/page/frontapp/FrontAlarmConfig.vue'),
          meta: { title: '边端状态预警' }
        },

        ///////////////下载列表////////////////
        {
          path: '/download/list',
          component: () => import(/* webpackChunkName: "downloadList" */ '../components/page/download/DownloadList.vue'),
          meta: { title: '下载列表' }
        },

        ///////////////智能巡检////////////////
        {
          path: '/pcscheck/mgr',
          component: () => import(/* webpackChunkName: "pcsManage" */ '../components/page/pcscheck/PcsManage.vue'),
          meta: { title: '巡检类型' }
        },
        {
          path: '/pcscheck/logs',
          component: () => import(/* webpackChunkName: "pcsLogs" */ '../components/page/pcscheck/PcsLogs.vue'),
          meta: { title: '巡检记录' }
        },
        {
          path: '/pcscheck/line',
          component: () => import(/* webpackChunkName: "pcsLines" */ '../components/page/pcscheck/PcsLines.vue'),
          meta: { title: '巡检路线' }
        },

        ///////////////增值服务////////////////
        {
          path: '/added/services/mgr',
          component: () => import(/* webpackChunkName: "servManage" */ '../components/page/services/ServManage.vue'),
          meta: { title: '服务管理' }
        },
        {
          path: '/added/services/subs',
          component: () => import(/* webpackChunkName: "servSubs" */ '../components/page/services/ServSubs.vue'),
          meta: { title: '服务订阅' }
        },


        {
          path: '/404',
          component: () => import(/* webpackChunkName: "404" */ '../components/page/404.vue'),
          meta: { title: '404' }
        },
        {
          path: '/403',
          component: () => import(/* webpackChunkName: "403" */ '../components/page/403.vue'),
          meta: { title: '403' }
        }
      ]
    },
    {
      path: '/login',
      component: () => import(/* webpackChunkName: "login" */ '../components/page/Login.vue'),
      meta: { title: '登录' }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
});

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 尾矿库云监控系统`;
  // const role = localStorage.getItem('ms_username');
  let user = localStorage.getItem('token');
  if (!user && to.path !== '/login') {
    next('/login');
    // } else if (to.meta.permission) {
    //     // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
    //     role === 'admin' ? next() : next('/403');
  } else if (to.path.startsWith('/home')) {
    next();
  } else {
    // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
    if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
      Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
        confirmButtonText: '确定'
      });
    } else {
      next();
    }
  }
});

export default router