<template>
  <div>
    <!-- 表格数据 -->
    <el-table :data="deviceInfoList" border stripe @row-click="clickRow">
      <el-table-column type="index"></el-table-column>
      <el-table-column label="图片" width="100px">
        <template slot-scope="scope">
          <el-popover placement="right" width="300" trigger="hover">
            <img
              :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg"
              height="300"
              width="300"
            />
            <img
              slot="reference"
              :src="COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg"
              height="50"
              width="50"
            />
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="设备名称" prop="name"></el-table-column>
      <el-table-column label="备注" prop="remark"></el-table-column>
      <el-table-column
        label="累计触发"
        prop="alarms.accumulated"
      ></el-table-column>
      <el-table-column label="屏蔽" prop="alarms.isShield" width="80px">
        <template slot-scope="scope">
          <el-tag
            v-if="scope.row.alarms != null && scope.row.alarms.isShield == 1"
            type="danger"
            >已屏蔽</el-tag
          >
          <el-tag v-else type="success">未屏蔽</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        label="蓝色限差（毫米）"
        prop="alarms.blueAlarmValue"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.alarms != null &&
              scope.row.alarms.blueAlarmValue != null
            "
          >
            {{ scope.row.alarms.blueRange }}小时内{{
              common.alarmTypeToString(
                scope.row.alarms.blueAlarmValue,
                scope.row.alarms.blueComparisonOperator
              )
            }}
          </span>
          <span v-else>未设置</span>
        </template>
      </el-table-column>
      <el-table-column
        label="黄色限差（毫米）"
        prop="alarms.yellowAlarmValue"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.alarms != null &&
              scope.row.alarms.yellowAlarmValue != null
            "
          >
            {{ scope.row.alarms.yellowRange }}小时内{{
              common.alarmTypeToString(
                scope.row.alarms.yellowAlarmValue,
                scope.row.alarms.yellowComparisonOperator
              )
            }}
          </span>
          <span v-else>未设置</span>
        </template>
      </el-table-column>
      <el-table-column
        label="橙色限差（毫米）"
        prop="alarms.orangeAlarmValue"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.alarms != null &&
              scope.row.alarms.orangeAlarmValue != null
            "
          >
            {{ scope.row.alarms.orangeRange }}小时内{{
              common.alarmTypeToString(
                scope.row.alarms.orangeAlarmValue,
                scope.row.alarms.orangeComparisonOperator
              )
            }}
          </span>
          <span v-else>未设置</span>
        </template>
      </el-table-column>
      <el-table-column
        label="红色限差（毫米）"
        prop="alarms.redAlarmValue"
        width="120px"
      >
        <template slot-scope="scope">
          <span
            v-if="
              scope.row.alarms != null && scope.row.alarms.redAlarmValue != null
            "
          >
            {{ scope.row.alarms.redRange }}小时内{{
              common.alarmTypeToString(
                scope.row.alarms.redAlarmValue,
                scope.row.alarms.redComparisonOperator
              )
            }}
          </span>
          <span v-else>未设置</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页组件 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryParams.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryParams.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    projectCode: String,
  },
  inheritAttrs: false,
  data () {
    return {
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      deviceInfoList: [],
    }
  },
  created () {

  },
  methods: {
    handleSizeChange (newSize) {
      this.queryParams.pageSize = newSize;
      this.getDeviceList(this.queryParams.projectCode);
    },
    handleCurrentChange (newPage) {
      this.queryParams.pageNo = newPage;
      this.getDeviceList(this.queryParams.projectCode);
    },
    async getDeviceList (projectCode) {
      if (projectCode != null) {
        this.queryParams.projectCode = projectCode;
      }
      const { data: res } = await this.$http.get("device/yuliang/find/withalarm/page", {
        params: this.queryParams
      });
      if (res.code == 0) {
        this.deviceInfoList = res.data.list;
        this.total = res.data.total;
      } else {
        this.$message.error(res.msg);
      }
    },
    clickRow (row, column, event) {
      this.handleSelectedRow(row);
    },
    handleSelectedRow (row) {
      this.$emit("onRowSelected", row);
    },
  }
}
</script>

<style lang="less" scoped>
</style>