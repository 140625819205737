<script type="text/javascript">
// const HTTP_HOST = "http://localhost:8100/";
// const HTTP_API_CTX = "http://localhost:8100/api/v1/";
// const WS_HOST = "ws://localhost:8100/ws/notification/";
// const BAIDU_MAP_AK = "B1sFVV9vgRCZEcjy87tyWig7DaITakno";

// const LOAD_DEVICE_IMG_URL = "http://localhost:8100/api/v1/file/img?fileName=";
// const PROJECT_FILE_UPLOAD_URL = "http://localhost:8100/api/v1/project/att/add";


const HTTP_HOST = "http://www.kuncesmos.com:8100/";
const HTTP_API_CTX = "http://www.kuncesmos.com:8100/api/v1/";
const WS_HOST = "ws://www.kuncesmos.com:8100/ws/notification/";
const BAIDU_MAP_AK = "B1sFVV9vgRCZEcjy87tyWig7DaITakno";

const LOAD_DEVICE_IMG_URL = "http://www.kuncesmos.com:8100/api/v1/file/img?fileName=";
const PROJECT_FILE_UPLOAD_URL = "http://www.kuncesmos.com:8100/api/v1/project/att/add";

// 暴露出这些属性和方法
export default {
  HTTP_HOST,
  HTTP_API_CTX,
  BAIDU_MAP_AK,
  WS_HOST,
  LOAD_DEVICE_IMG_URL,
  PROJECT_FILE_UPLOAD_URL,
};
</script>