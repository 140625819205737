var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{attrs:{"data":_vm.deviceInfoList,"border":"","stripe":""},on:{"row-click":_vm.clickRow}},[_c('el-table-column',{attrs:{"type":"index"}}),_c('el-table-column',{attrs:{"label":"图片","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popover',{attrs:{"placement":"right","width":"300","trigger":"hover"}},[_c('img',{attrs:{"src":_vm.COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg,"height":"300","width":"300"}}),_c('img',{attrs:{"slot":"reference","src":_vm.COMMON_CONFIG.LOAD_DEVICE_IMG_URL + scope.row.deviceImg,"height":"50","width":"50"},slot:"reference"})])]}}])}),_c('el-table-column',{attrs:{"label":"设备名称","prop":"name"}}),_c('el-table-column',{attrs:{"label":"备注","prop":"remark"}}),_c('el-table-column',{attrs:{"label":"渗流压力","prop":"slyali"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.slyali === '0')?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("否")]):_c('el-tag',{attrs:{"type":"success"}},[_vm._v("是")])]}}])}),_c('el-table-column',{attrs:{"label":"累计触发","prop":"alarms.accumulated"}}),_c('el-table-column',{attrs:{"label":"屏蔽","prop":"alarms.isShield","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.alarms != null && scope.row.alarms.isShield == 1)?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("已屏蔽")]):_c('el-tag',{attrs:{"type":"success"}},[_vm._v("未屏蔽")])]}}])}),_c('el-table-column',{attrs:{"label":"蓝色限差","prop":"alarms.blueAlarmValue","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.alarms != null &&
            scope.row.alarms.blueAlarmValue != null
          )?_c('span',[_vm._v(_vm._s(scope.row.alarms.blueAlarmValue))]):_c('span',[_vm._v("未设置")])]}}])}),_c('el-table-column',{attrs:{"label":"黄色限差","prop":"alarms.yellowAlarmValue","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.alarms != null &&
            scope.row.alarms.yellowAlarmValue != null
          )?_c('span',[_vm._v(_vm._s(scope.row.alarms.yellowAlarmValue))]):_c('span',[_vm._v("未设置")])]}}])}),_c('el-table-column',{attrs:{"label":"橙色限差","prop":"alarms.orangeAlarmValue","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.alarms != null &&
            scope.row.alarms.orangeAlarmValue != null
          )?_c('span',[_vm._v(_vm._s(scope.row.alarms.orangeAlarmValue))]):_c('span',[_vm._v("未设置")])]}}])}),_c('el-table-column',{attrs:{"label":"红色限差","prop":"alarms.redAlarmValue","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
            scope.row.alarms != null && scope.row.alarms.redAlarmValue != null
          )?_c('span',[_vm._v(_vm._s(scope.row.alarms.redAlarmValue))]):_c('span',[_vm._v("未设置")])]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.queryParams.pageNo,"page-sizes":[10, 20, 50, 100],"page-size":_vm.queryParams.pageSize,"layout":"total, sizes, prev, pager, next","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }