<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>预警管理</el-breadcrumb-item>
      <el-breadcrumb-item>预警配置</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row>
        <el-col :span="4">
          <el-select
            v-model="queryParams.projectCode"
            placeholder="请选择项目"
            clearable
            @clear="getDeviceList"
            @change="getDeviceList"
          >
            <el-option
              v-for="item in projectInfoList"
              :key="item.projectCode"
              :label="item.projectName"
              :value="item.projectCode"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>

      <el-row :gutter="5">
        <el-col :span="17">
          <el-tabs
            type="border-card"
            v-model="tabActiveName"
            @tab-click="handleClick"
            ref="tabss"
          >
            <el-tab-pane label="内部位移" name="cexie">
              <v-cexie-alarm-config
                :projectCode="queryParams.projectCode"
                ref="cexieRef"
                @onRowSelected="rowSelected"
              >
              </v-cexie-alarm-config>
            </el-tab-pane>
            <el-tab-pane label="渗压计" name="shenya">
              <v-shenya-alarm-config
                :projectCode="queryParams.projectCode"
                ref="shenyaRef"
                @onRowSelected="rowSelected"
              >
              </v-shenya-alarm-config>
            </el-tab-pane>
            <el-tab-pane label="水位计" name="wuwei">
              <v-wuwei-alarm-config
                :projectCode="queryParams.projectCode"
                ref="wuweiRef"
                @onRowSelected="rowSelected"
              >
              </v-wuwei-alarm-config>
            </el-tab-pane>
            <el-tab-pane label="雨量计" name="yuliang">
              <v-yuliang-alarm-config
                :projectCode="queryParams.projectCode"
                ref="yuliangRef"
                @onRowSelected="rowSelected"
              >
              </v-yuliang-alarm-config>
            </el-tab-pane>
            <el-tab-pane label="浊度仪" name="zhuodu">
              <v-zhuodu-alarm-config
                :projectCode="queryParams.projectCode"
                ref="zhuoduRef"
                @onRowSelected="rowSelected"
              ></v-zhuodu-alarm-config>
            </el-tab-pane>
            <el-tab-pane label="干滩" name="gantan">
              <v-gantan-alarm-config
                :projectCode="queryParams.projectCode"
                ref="gantanRef"
                @onRowSelected="rowSelected"
              >
              </v-gantan-alarm-config>
            </el-tab-pane>
            <el-tab-pane label="安全超高" name="chaogao">
              <v-chaogao-alarm-config
                :projectCode="queryParams.projectCode"
                ref="chaogaoRef"
                @onRowSelected="rowSelected"
              ></v-chaogao-alarm-config>
            </el-tab-pane>
            <el-tab-pane label="明渠流量" name="mingqu">
              <v-mingqu-alarm-config
                :projectCode="queryParams.projectCode"
                ref="mingquRef"
                @onRowSelected="rowSelected"
              ></v-mingqu-alarm-config>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :span="7">
          <el-card style="margin-top: 10px">
            <v-cexie-alarm-form
              ref="cexieForm"
              v-if="tabActiveName === 'cexie'"
              @onAlarmSave="getDeviceList"
            ></v-cexie-alarm-form>
            <v-shenya-alarm-form
              ref="shenyaForm"
              v-if="tabActiveName === 'shenya'"
              @onAlarmSave="getDeviceList"
            ></v-shenya-alarm-form>
            <v-wuwei-alarm-form
              ref="wuweiForm"
              v-if="tabActiveName === 'wuwei'"
              @onAlarmSave="getDeviceList"
            ></v-wuwei-alarm-form>
            <v-yuliang-alarm-form
              ref="yuliangForm"
              v-if="tabActiveName === 'yuliang'"
              @onAlarmSave="getDeviceList"
            ></v-yuliang-alarm-form>
            <v-zhuodu-alarm-form
              ref="zhuoduForm"
              v-if="tabActiveName === 'zhuodu'"
              @onAlarmSave="getDeviceList"
            ></v-zhuodu-alarm-form>
            <v-chaogao-alarm-form
              ref="chaogaoForm"
              v-if="tabActiveName === 'chaogao'"
              @onAlarmSave="getDeviceList"
            ></v-chaogao-alarm-form>
            <v-gantan-alarm-form
              ref="gantanForm"
              v-if="tabActiveName === 'gantan'"
              @onAlarmSave="getDeviceList"
            ></v-gantan-alarm-form>
            <v-mingqu-alarm-form
              ref="mingquForm"
              v-if="tabActiveName === 'mingqu'"
              @onAlarmSave="getDeviceList"
            ></v-mingqu-alarm-form>
          </el-card>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import vCexieAlarmConfig from "./CexieAlarmConfig.vue";
import vCexieAlarmForm from "./CexieAlarmForm.vue";
import vShenyaAlarmConfig from "./ShenyaAlarmConfig.vue";
import vShenyaAlarmForm from "./ShenyaAlarmForm.vue";
import vWuweiAlarmConfig from "./WuweiAlarmConfig.vue";
import vWuweiAlarmForm from "./WuweiAlarmForm.vue";
import vYuliangAlarmConfig from "./YuliangAlarmConfig.vue";
import vYuliangAlarmForm from "./YuliangAlarmForm.vue";
import vZhuoduAlarmConfig from "./ZhuoduAlarmConfig.vue";
import vZhuoduAlarmForm from "./ZhuoduAlarmForm.vue";
import vChaogaoAlarmConfig from "./ChaogaoAlarmConfig.vue";
import vChaogaoAlarmForm from "./ChaogaoAlarmForm.vue";
import vGantanAlarmConfig from "./GantanAlarmConfig.vue";
import vGantanAlarmForm from "./GantanAlarmForm.vue";
import vMingquAlarmConfig from "./MingquAlarmConfig.vue";
import vMingquAlarmForm from "./MingquAlarmForm.vue";
export default {
  components: {
    vCexieAlarmConfig,
    vCexieAlarmForm,
    vShenyaAlarmConfig,
    vShenyaAlarmForm,
    vWuweiAlarmConfig,
    vWuweiAlarmForm,
    vYuliangAlarmConfig,
    vYuliangAlarmForm,
    vZhuoduAlarmConfig,
    vZhuoduAlarmForm,
    vChaogaoAlarmConfig,
    vChaogaoAlarmForm,
    vGantanAlarmConfig,
    vGantanAlarmForm,
    vMingquAlarmConfig,
    vMingquAlarmForm,
  },
  data () {
    return {
      tabActiveName: 'cexie',
      queryParams: {
        projectCode: "",
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      projectInfoList: [],
    }
  },
  created () {
    this.getAllProject();
    // 通过权限控制展示模块
    this.$nextTick(() => {
      let cexie = false;
      let shenya = false;
      let wuwei = false;
      let yuliang = false;
      let zhuodu = false;
      let gantan = false;
      let chaogao = false;
      let mingqu = false;
      let permsStr = localStorage.getItem("user_perms");
      let permsAry = permsStr.split(",");
      permsAry.forEach(o => {
        if (o == 'sys:mtr:cexie') {
          cexie = true;
        }
        if (o == 'sys:mtr:shenya') {
          shenya = true;
        }
        if (o == 'sys:mtr:wuwei') {
          wuwei = true;
        }
        if (o == 'sys:mtr:yuliang') {
          yuliang = true;
        }
        if (o == 'sys:mtr:zhuodu') {
          zhuodu = true;
        }
        if (o == 'sys:mtr:gantan') {
          gantan = true;
        }
        if (o == 'sys:mtr:chaogao') {
          chaogao = true;
        }
        if (o == 'sys:mtr:mingqu') {
          mingqu = true;
        }
      });
      if (!cexie) {
        this.$refs.tabss.$children[0].$refs.tabs[0].style.display = 'none';
      }
      if (!shenya) {
        this.$refs.tabss.$children[0].$refs.tabs[1].style.display = 'none';
      }
      if (!wuwei) {
        this.$refs.tabss.$children[0].$refs.tabs[2].style.display = 'none';
      }
      if (!yuliang) {
        this.$refs.tabss.$children[0].$refs.tabs[3].style.display = 'none';
      }
      if (!zhuodu) {
        this.$refs.tabss.$children[0].$refs.tabs[4].style.display = 'none';
      }
      if (!gantan) {
        this.$refs.tabss.$children[0].$refs.tabs[5].style.display = 'none';
      }
      if (!chaogao) {
        this.$refs.tabss.$children[0].$refs.tabs[6].style.display = 'none';
      }
      if (!mingqu) {
        this.$refs.tabss.$children[0].$refs.tabs[7].style.display = 'none';
      }
    });
  },
  methods: {
    handleClick (tab, event) {
      this.tabActiveName = tab.name;
      this.getDeviceList();
    },
    async getAllProject () {
      const { data: res } = await this.$http.get("project/find/all");
      if (res.code == 0) {
        this.projectInfoList = res.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    getDeviceList () {
      if (this.queryParams.projectCode == null || this.queryParams.projectCode == '') {
        // this.$message.error('请选择项目.');
        return;
      }

      if (this.tabActiveName === 'cexie') {
        this.$refs.cexieRef.getDeviceList(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'shenya') {
        this.$refs.shenyaRef.getDeviceList(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'wuwei') {
        this.$refs.wuweiRef.getDeviceList(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'yuliang') {
        this.$refs.yuliangRef.getDeviceList(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'zhuodu') {
        this.$refs.zhuoduRef.getDeviceList(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'chaogao') {
        this.$refs.chaogaoRef.getDeviceList(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'gantan') {
        this.$refs.gantanRef.getDeviceList(this.queryParams.projectCode);
      } else if (this.tabActiveName === 'mingqu') {
        this.$refs.mingquRef.getDeviceList();
      }
    },
    rowSelected (row) {
      if (this.tabActiveName === 'cexie') {
        this.$refs.cexieForm.getByDeviceCode(row);
      } else if (this.tabActiveName === 'shenya') {
        this.$refs.shenyaForm.getByDeviceCode(row);
      } else if (this.tabActiveName === 'wuwei') {
        this.$refs.wuweiForm.getByDeviceCode(row);
      } else if (this.tabActiveName === 'yuliang') {
        this.$refs.yuliangForm.getByDeviceCode(row);
      } else if (this.tabActiveName === 'zhuodu') {
        this.$refs.zhuoduForm.getByDeviceCode(row);
      } else if (this.tabActiveName === 'chaogao') {
        this.$refs.chaogaoForm.getByDeviceCode(row);
      } else if (this.tabActiveName === 'gantan') {
        this.$refs.gantanForm.getByDeviceCode(row);
      } else if (this.tabActiveName === 'mingqu') {
        this.$refs.mingquForm.getByDeviceCode(row);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.el-tabs {
  margin-top: 10px;
}
</style>