<template>
  <div class="loading">
      <div class="loader"></div>
  </div>
</template>
 
<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
};
</script>
 
<style scoped>
</style>