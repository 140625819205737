<template>
  <div>
    <el-form
      :model="alarmInfoForm"
      :rules="alarmRules"
      ref="alarmInfoRef"
      label-width="80px"
      disabled
    >
      <el-form-item label="所属设备" prop="deviceName">
        <el-input v-model="alarmInfoForm.deviceName" disabled> </el-input>
      </el-form-item>
      <el-divider content-position="left">蓝色预警设置</el-divider>
      <el-form-item label="" prop="blueAlarmValue" label-width="10px">
        <el-row>
          <el-col :span="7">
            <el-input
              v-model="alarmInfoForm.blueRange"
              placeholder="n小时内"
              clearable
            >
            </el-input>
          </el-col>
          <el-col :span="17">
            <el-input
              autocomplete="off"
              v-model="alarmInfoForm.blueAlarmValue"
              clearable
            >
              <el-select
                v-model="alarmInfoForm.blueComparisonOperator"
                slot="prepend"
                placeholder="选择"
                style="width: 70px"
              >
                <el-option label="=" :value="1"></el-option>
                <el-option label=">" :value="2"></el-option>
                <el-option label="<" :value="3"></el-option>
                <el-option label="≥" :value="4"></el-option>
                <el-option label="≤" :value="5"></el-option>
                <el-option label="<>" :value="6"></el-option>
              </el-select>
              <template slot="append">立方米</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-divider content-position="left">黄色预警设置</el-divider>
      <el-form-item label="" prop="yellowAlarmValue" label-width="10px">
        <el-row>
          <el-col :span="7">
            <el-input
              v-model="alarmInfoForm.yellowRange"
              placeholder="n小时内"
              clearable
            >
            </el-input>
          </el-col>
          <el-col :span="17">
            <el-input
              autocomplete="off"
              v-model="alarmInfoForm.yellowAlarmValue"
              clearable
            >
              <el-select
                v-model="alarmInfoForm.yellowComparisonOperator"
                slot="prepend"
                placeholder="选择"
                style="width: 70px"
              >
                <el-option label="=" :value="1"></el-option>
                <el-option label=">" :value="2"></el-option>
                <el-option label="<" :value="3"></el-option>
                <el-option label="≥" :value="4"></el-option>
                <el-option label="≤" :value="5"></el-option>
                <el-option label="<>" :value="6"></el-option>
              </el-select>
              <template slot="append">立方米</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-divider content-position="left">橙色预警设置</el-divider>
      <el-form-item label="" prop="orangeAlarmValue" label-width="10px">
        <el-row>
          <el-col :span="7">
            <el-input
              v-model="alarmInfoForm.orangeRange"
              placeholder="n小时内"
              clearable
            >
            </el-input>
          </el-col>
          <el-col :span="17">
            <el-input
              autocomplete="off"
              v-model="alarmInfoForm.orangeAlarmValue"
              clearable
            >
              <el-select
                v-model="alarmInfoForm.orangeComparisonOperator"
                slot="prepend"
                placeholder="选择"
                style="width: 70px"
              >
                <el-option label="=" :value="1"></el-option>
                <el-option label=">" :value="2"></el-option>
                <el-option label="<" :value="3"></el-option>
                <el-option label="≥" :value="4"></el-option>
                <el-option label="≤" :value="5"></el-option>
                <el-option label="<>" :value="6"></el-option>
              </el-select>
              <template slot="append">立方米</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-divider content-position="left">红色预警设置</el-divider>
      <el-form-item label="" prop="redAlarmValue" label-width="10px">
        <el-row>
          <el-col :span="7">
            <el-input
              v-model="alarmInfoForm.redRange"
              placeholder="n小时内"
              clearable
            >
            </el-input>
          </el-col>
          <el-col :span="17">
            <el-input
              autocomplete="off"
              v-model="alarmInfoForm.redAlarmValue"
              clearable
            >
              <el-select
                v-model="alarmInfoForm.redComparisonOperator"
                slot="prepend"
                placeholder="选择"
                style="width: 70px"
              >
                <el-option label="=" :value="1"></el-option>
                <el-option label=">" :value="2"></el-option>
                <el-option label="<" :value="3"></el-option>
                <el-option label="≥" :value="4"></el-option>
                <el-option label="≤" :value="5"></el-option>
                <el-option label="<>" :value="6"></el-option>
              </el-select>
              <template slot="append">立方米</template>
            </el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-divider content-position="left">是否屏蔽</el-divider>
      <el-form-item label="" prop="isShield">
        <el-radio-group v-model="alarmInfoForm.isShield">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-divider content-position="left"
        >累计触发<el-tooltip
          class="item"
          effect="dark"
          content="监测值连续异常次数超过‘累计触发’才会触发预警."
          placement="top"
        >
          <i
            class="el-icon-warning"
            style="float: right; padding: 3px 0"
          ></i> </el-tooltip
      ></el-divider>
      <el-form-item prop="accumulated">
        <el-select v-model="alarmInfoForm.accumulated" placeholder="请选择">
          <el-option label="1" :value="1"></el-option>
          <el-option label="2" :value="2"></el-option>
          <el-option label="3" :value="3"></el-option>
          <el-option label="4" :value="4"></el-option>
          <el-option label="5" :value="5"></el-option>
          <el-option label="6" :value="6"></el-option>
          <el-option label="7" :value="7"></el-option>
          <el-option label="8" :value="8"></el-option>
          <el-option label="9" :value="9"></el-option>
          <el-option label="10" :value="10"></el-option>
          <el-option label="11" :value="11"></el-option>
          <el-option label="12" :value="12"></el-option>
          <el-option label="13" :value="13"></el-option>
          <el-option label="14" :value="14"></el-option>
          <el-option label="15" :value="15"></el-option>
          <el-option label="16" :value="16"></el-option>
          <el-option label="17" :value="17"></el-option>
          <el-option label="18" :value="18"></el-option>
          <el-option label="19" :value="19"></el-option>
          <el-option label="20" :value="20"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item>
        <el-button @click="resetData()" v-has="'sys:alarm:setting'"
          >重 置</el-button
        >
        <el-button
          type="primary"
          @click="editInfoSubmit()"
          v-has="'sys:alarm:setting'"
          >保 存</el-button
        >
      </el-form-item> -->
    </el-form>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  data () {
    return {
      alarmRules: {},
      alarmInfoForm: {
        deviceCode: '',
        deviceName: '',
      },
    };
  },
  created () { },
  methods: {
    resetData () {
      this.$refs.alarmInfoRef.resetFields();
    },
    async editInfoSubmit () {
      const { data: res } = await this.$http.post(
        "alarm/config",
        this.alarmInfoForm
      );
      if (res.code == 0) {
        this.$message.success("设置成功");
        this.handleOnSave();
      } else {
        this.$message.error(res.msg);
        return false;
      }
    },
    async getByDeviceCode (row) {
      const { data: res } = await this.$http.get("alarm/find/config", {
        params: {
          deviceCode: row.deviceCode
        }
      });

      if (res.code == 0 && res.data != null) {
        this.alarmInfoForm = res.data;
        this.alarmInfoForm.deviceCode = row.deviceCode;
        this.alarmInfoForm.deviceName = row.name;
      }
    },
    handleOnSave () {
      this.$emit("onAlarmSave");
    },
  },
};
</script>

<style lang="less" scoped>
</style>