export default {
  floatFormat: function (value, fractionDigits) {
    if (value == null) {
      return 0.0;
    }

    return parseFloat(parseFloat(value).toFixed(fractionDigits));
  },
  dateFormat: function (fmt, date) {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "M+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "m+": date.getMinutes().toString(),         // 分
      "s+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  },
  todayDateStr: function (fmt, date) {
    let ret;
    const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "M+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": "00",           // 时
      "m+": "00",         // 分
      "s+": "00"          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  },
  tomorrowDateStr: function (fmt, date) {
    let yr = date.getFullYear().toString();
    let mt = (date.getMonth() + 1).toString();
    let dte = (date.getDate() + 1).toString();
    var d = new Date(date.getFullYear(), (date.getMonth() + 1), 0);
    // 获取当月最大天数
    let maxDay = d.getDate();
    if ((date.getDate() + 1) > maxDay) {
      if ((date.getMonth() + 2) > 12) {
        yr = (date.getFullYear() + 1).toString();
        mt = '01';
      } else {
        mt = (date.getMonth() + 2).toString();
      }
      dte = '01';
    }
    let ret;
    const opt = {
      "Y+": yr,        // 年
      "M+": mt,     // 月
      "d+": dte,      // 日
      "H+": "00",           // 时
      "m+": "00",         // 分
      "s+": "00"          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
        fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
    };
    return fmt;
  },
  zhuoduInput: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "-15mV~+15mV";
        break;
      case 2:
        result = "-50mV~+50mV";
        break;
      case 3:
        result = "-100mV~+100mV";
        break;
      case 4:
        result = "-500mV~+500mV";
        break;
      case 5:
        result = "-1V~+1V";
        break;
      case 6:
        result = "-5V~+5V/-10V~+10V";
        break;
      case 7:
        result = "-20mA~+20mA备用";
        break;
      case 8:
        result = "4~20mA备用";
        break;
      case 9:
        result = "-20mA~+20mA外置电阻";
        break;
      case 10:
        result = "4~20mA外置电阻";
        break;
      case 11:
        result = "J型";
        break;
      case 12:
        result = "K型";
        break;
      case 13:
        result = "T型";
        break;
      case 14:
        result = "E型";
        break;
      case 15:
        result = "R型";
        break;
      case 16:
        result = "S型";
        break;
      case 17:
        result = "B型";
        break;
      case 18:
        result = "钨铼WEB3/25";
        break;
      case 19:
        result = "钨铼WEB3/26";
        break;
      default:
        break;
    }
    return result;
  },
  alarmTypeToString: function (value, operator) {
    let result = "";
    switch (operator) {
      case 1:
        result = "= ";
        break;
      case 2:
        result = "＞ ";
        break;
      case 3:
        result = "＜ ";
        break;
      case 4:
        result = "≥ ";
        break;
      case 5:
        result = "≤ ";
        break;
      case 6:
        result = "<> ";
        break;
      default:
        break;
    }
    return result + (value == null ? "未设置" : value);
  },
  wuweiTypeToString: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "水位计";
        break;
      case 2:
        result = "库水位";
        break;
      case 3:
        result = "高程";
        break;
      default:
        break;
    }
    return result;
  },
  getIsMainType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "是";
        break;
      case 0:
        result = "否";
        break;
      default:
        break;
    }
    return result;
  },
  gnssJzType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "基准站";
        break;
      case 2:
        result = "监测站";
        break;
      default:
        break;
    }
    return result;
  },
  gnssZhubanType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "RTCM2";
        break;
      case 2:
        result = "RTCM3";
        break;
      case 3:
        result = "诺瓦泰OEM4+";
        break;
      case 4:
        result = "Septentrio";
        break;
      case 5:
        result = "U-blox";
        break;
      case 6:
        result = "半球";
        break;
      case 7:
        result = "BINEX";
        break;
      case 8:
        result = "天宝RT17R27";
        break;
      case 9:
        result = "司南";
        break;
      default:
        break;
    }
    return result;
  },
  gnssPinlvType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "单频";
        break;
      case 2:
        result = "双频";
        break;
      case 3:
        result = "多频";
        break;
      default:
        break;
    }
    return result;
  },
  gnssRemoteConnType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "服务器";
        break;
      case 2:
        result = "客户端";
        break;
      case 3:
        result = "CORS";
        break;
      case 4:
        result = "串口";
        break;
      default:
        break;
    }
    return result;
  },
  gnssCoordinateType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "WGS84";
        break;
      case 2:
        result = "七参数";
        break;
      case 3:
        result = "北京54";
        break;
      case 4:
        result = "全国80";
        break;
      default:
        break;
    }
    return result;
  },
  gnssAvaliType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "是";
        break;
      case 0:
        result = "否";
        break;
      default:
        break;
    }
    return result;
  },
  gnssNodeType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "移动站";
        break;
      case 0:
        result = "基准站";
        break;
      default:
        break;
    }
    return result;
  },
  quanzhanNodeType: function (type) {
    let result = "";
    switch (type) {
      case 1:
        result = "监测点";
        break;
      case 2:
        result = "控制点";
        break;
      default:
        break;
    }
    return result;
  },
  triggerDate: function (date) {
    if (date == null) {
      return true;
    }
    let d1 = date[0].getTime();
    let d2 = date[1].getTime();
    let offset = 30 * 24 * 60 * 60 * 1000;
    if (d2 - d1 > offset) {
      return false;
    } else {
      return true;
    }
  },
  // 位移矢量
  weiyiSl: function (x, y) {
    var xx = this.floatFormat(x, 2);
    var yy = this.floatFormat(y, 2);

    var x2 = xx * xx;
    var y2 = yy * yy;

    var result = Math.sqrt((x2 + y2));
    return this.floatFormat(result, 2);
  },
  // 位移水平方向
  weiyisp: function (x, y) {
    var xx = this.floatFormat(x, 2);
    var yy = this.floatFormat(y, 2);

    var x2 = xx * xx;
    var y2 = yy * yy;

    if (xx >= 0 && yy >= 0) {
			return "东北↗(坝体下游)";
		} else if (xx >= 0 && yy < 0) {
			return "西北↖(坝体上游)";
		} else if (xx < 0 && yy < 0) {
			return "西南↙(坝体上游)";
		} else if (yy >= 0 && xx < 0) {
			return "东南↘(坝体下游)";
		}
		
		return "";
  },
  // 位移垂直方向
  weiyicz: function (z) {
    var zz = this.floatFormat(z, 2);

    if (zz < 0) {
			return "上隆↑";
		} else {
			return "沉降↓";
		}
  },
  // 判断是否过时
  outOfTime: function (date) {
    if (date == null) {
      return false;
    }

    var lowDate = new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate() - 1);
    var t1 = lowDate.getTime();
    var datestr = date.replace(/-/g, "/");
    var t2 = (new Date(datestr)).getTime();
    if (t2 < t1) {
      return true;
    } else {
      return false;
    }
  }
}