import { render, staticRenderFns } from "./MingquAlarmForm.vue?vue&type=template&id=70dd3d33&scoped=true&"
import script from "./MingquAlarmForm.vue?vue&type=script&lang=js&"
export * from "./MingquAlarmForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70dd3d33",
  null
  
)

export default component.exports